<template>
  <div class="pull-container" ref="container" :style="containerStyle">
    <div id="not-full-screen" v-if="isFullScreen === false">
      {{ $t("common.foFullScreen") }}
      <br/><br/>
      <button class="button is-default" @click="enableFullScreen">
        {{ $t("common.foFullScreenAction") }}
      </button>
    </div>
    <img
      v-if="isFullScreen || fullscreenAsked"
      :src="slideToDisplay.imageUrl"/>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import isSafari from '@/functions/safari'

export default {
  name: 'ScenePullAnimation',
  props: ['attributes', 'number'],
  data () {
    return {
      cumulativeY: 0,
      isFullScreen: null,
      fullscreenAsked: false,
      isSafari: isSafari(),
      animationSuccess: false,
      containerHeight: null,
      containerHeightLandscape: null,
      containerStyle: {}
    }
  },
  mounted () {
    window.addEventListener('devicemotion', this.onDeviceMotion)
    this.isFullScreen = screenfull.isFullscreen
    if (this.isFullScreen === undefined) {
      // Safari / iOS
      this.fullscreenAsked = true
      this.forcePortrait()
      return
    }
    if (this.isFullScreen) {
      this.forcePortrait()
    }
  },
  unmounted () {
    window.removeEventListener('devicemotion', this.onDeviceMotion)
    if (this.isSafari || screen.orientation.unlock === undefined) {
      this.$store.commit('FORCE_IOS_SCREEN_ORIENTATION', null)
    } else {
      screen.orientation.unlock()
    }
  },
  computed: {
    slideToDisplay () {
      let slide = null
      for (slide of this.attributes.animation) {
        if (this.cumulativeY <= slide.z) {
          break
        }
      }
      return slide
    },
    audioTrack () {
      return this.$store.state.audio.tracks[1]
    }
  },
  watch: {
    slideToDisplay (value) {
      // When slide changes, and there is a sound attached to it, play the sound
      if (!value.soundUrl) {
        return
      }
      this.audioTrack.volume = 1
      this.audioTrack.src = value.soundUrl
    }
  },
  methods: {
    calculateHeights () {
      const parent = this.$refs.container.closest('.scrollable-zone')
      if (parent !== null) {
        const component = this
        setTimeout(function () {
          if (!component.isSafari || window.innerHeight > window.innerWidth) {
            component.containerHeight = parent.clientHeight - 10
            component.containerHeightLandscape = parent.clientWidth - 10
          } else {
            component.containerHeight = parent.clientWidth - 10
            component.containerHeightLandscape = parent.clientHeight - 10
          }
          component.refreshContainerStyle()
        }, 1000)
      }
    },
    refreshContainerStyle () {
      this.containerStyle = {}
      if (this.containerHeight !== null) {
        if (window.innerHeight > window.innerWidth) {
          this.containerStyle = {
            'max-height': this.containerHeight.toString() + 'px'
          }
        } else {
          this.containerStyle = {
            'max-height': this.containerHeightLandscape.toString() + 'px'
          }
        }
      }
    },
    enableFullScreen () {
      console.log('enableFullScreen')
      this.fullscreenAsked = true
      const comp = this
      screenfull.on('change', () => {
        if (screenfull.isFullscreen) {
          comp.isFullScreen = true
          comp.forcePortrait()
        }
      })
      screenfull.request()
    },
    forcePortrait () {
      this.calculateHeights()
      console.log('forcePortrait')
      // Lock screen display
      if (!this.isSafari && screen.orientation.lock !== undefined) {
        screen.orientation.lock('portrait')
      } else {
        this.$store.commit('FORCE_IOS_SCREEN_ORIENTATION', 'portrait')
      }
    },
    onDeviceMotion (evt) {
      this.refreshContainerStyle()
      if (this.animationSuccess) {
        // Already successful
        return
      }

      const current = evt.acceleration
      const treshold = 5
      if (current.y > treshold) {
        this.cumulativeY += current.y
      }
      this.updateSuccess()
    },
    validate () {
      this.$store.dispatch('validateItem', {
        number: this.number
      })
    },
    // Are we in a successful state ?
    updateSuccess () {
      if (this.animationSuccess) {
        // Already successful
        return
      }
      const lastSlideIndex = this.attributes.animation.length - 1
      if (this.slideToDisplay.imageUrl === this.attributes.animation[lastSlideIndex].imageUrl) {
        if (this.attributes.autoValidateAtEnd) {
          this.animationSuccess = true
          this.validate()
        }
      }
    },
    onDisplaySuccessButton (el) {
      setTimeout(function () {
        el.scrollIntoView({ behavior: 'smooth' })
      }, 500)
    }
  }
}
</script>

<style scoped lang="sass">
  img
    max-width: 100%
    max-height: 100%
    display: block
  .not-working-button
    padding: 35px 30px
  .pull-container
    display: flex
    max-height: 100%
    height: 100%
</style>
